import React from "react";

import SEO from "src/components/SEO/SEO";

import * as styles from "./404.module.scss";

const NotFoundPage = ({ pageContext }: any) => {
  return <div>404</div>;
};

export default NotFoundPage;
